import create from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  dopen: true,
  rows: [],
  username: '',
  useremail: '',
  password: '',
  categories: [],
  messages: [],
  users: [],
  healers: [],
  patient: [],
  token: '',
  utype: '',
  uid: '',
  questions: [],
  yourhealers: [],
  yourpatients: [],
  chakra: [],
  major: [],
  minor: [],
  organ: [],
  chakracategories: [],
  updateChakraCategories: (chakracategories) => set((state) => ({ chakracategories: chakracategories })),
  updateChakra: (chakra) => set((state) => ({ chakra: chakra })),
  updateMajor: (major) => set((state) => ({ major: major })),
  updateMinor: (minor) => set((state) => ({ minor: minor })),
  updateOrgan: (organ) => set((state) => ({ organ: organ })),
  updateUserEmail: (useremail) => set((state) => ({ useremail: useremail })),
  updateYourPatients: (yourpatients) => set((state) => ({ yourpatients: yourpatients })),
  updateYourHealers: (yourhealers) => set((state) => ({ yourhealers: yourhealers })),
  updateQuestions: (questions) => set((state) => ({ questions: questions })),
  updatePatient: (patient) => set((state) => ({ patient: patient })),
  updateHealers: (healers) => set((state) => ({ healers: healers })),
  updateUid: (uid) => set((state) => ({ uid: uid })),
  updateUtype: (utype) => set((state) => ({ utype: utype })),
  updateToken: (token) => set((state) => ({ token: token })),
  updateUsers: (users) => set((state) => ({ users: users })),
  updateMessages: (messages) => set((state) => ({ messages: messages })),
  updateCategories: (categories) => set((state) => ({ categories: categories })),
  updateUsername: (username) => set((state) => ({ username: username })),
  updatePassword: (password) => set((state) => ({ password: password })),
  setRows: (rows) => set((state) => ({ rows: rows })),
  updateDopen: (dopen) => set((state) => ({ dopen: dopen })),
});

appStore = persist(appStore, { name: "cdot_store_api" });
export const useAppStore = create(appStore);
