import { useState } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";

export default function AddScanning() {
    const { register, handleSubmit } = useForm();
    const [patientid, setPatientid] = useState("");
    const [label, setLabel] = useState("");
    const [type, setType] = useState("");
    const baseURL = process.env.REACT_APP_API_URL;
    const [session, setSession] = useState("");
    const token = useAppStore((state) => state.token);
    const patient = useAppStore((state) => state.patient);
    const useremail = useAppStore((state) => state.useremail);
    const navigate = useNavigate();
    const chakra = useAppStore((state) => state.chakra);
    const major = useAppStore((state) => state.major);
    const minor = useAppStore((state) => state.minor);
    const organ = useAppStore((state) => state.organ);
    const [question, setQuestion] = useState([
        {
            chakra1: "",
            major1: "",
            minor1: "",
            organ1: "",
            comment1: "",
            chakrastatus1: "",
            majorstattus1: "",
            minorstatus1: "",
            organstatus1: "",
            commentstatus1: "",
        },
    ]);

    const handlePatientIdChange = (event) => {
        setPatientid(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleSessionChange = (event) => {
        setSession(event.target.value);
    };

    const addQuestion = () => {
        let qarray = {
            chakra1: "",
            major1: "",
            minor1: "",
            organ1: "",
            comment1: "",
            chakrastatus1: "",
            majorstattus1: "",
            minorstatus1: "",
            organstatus1: "",
            commentstatus1: "",
        };
        setQuestion((question) => [...question, qarray]);
    };

    const removeQuestion = () => {
        setQuestion((question) => question.slice(0, -1));
    };

    const onSubmit = async (data) => {
        const entries = Object.entries(data);
        var que = [];
        var ans = [];
        entries.forEach((value, index) => {
            que.push(value);
        });
        console.log("que: ", que);
        const outputArray = que.reduce((acc, [key, value]) => {
            const match = key.match(/^(.*?)(\d+)$/);
            if (match) {
                const [, base, num] = match;
                const index = parseInt(num) - 1;
                if (!acc[index]) {
                    acc[index] = [];
                }
                acc[index].push([base, value]);
            }
            return acc;
        }, []);

        // console.log(outputArray);
        createUser(data, outputArray, ans);
    };

    const createUser = async (data, que, ans) => {
        let posturl = baseURL + "scanning";

        await axios
            .post(
                posturl,
                {
                    patient_id: patientid,
                    type: question.length,
                    session: session,
                    healer_email: useremail,
                    question: que,
                    answer: data,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                navigate("/scanning");
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Typography variant="h5" align="center">
                                    Add Scanning Status
                                </Typography>
                                <Box height={10} />
                                <IconButton
                                    style={{
                                        position: "absolute",
                                        top: "10",
                                        left: "20",
                                    }}
                                    onClick={() => {
                                        navigate("/scanning");
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <Box height={50} />
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            error={false}
                                            id="session"
                                            name="session"
                                            value={session}
                                            onChange={handleSessionChange}
                                            label="Session"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        >
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Patient
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={patientid}
                                                onBlur={handlePatientIdChange}
                                                onChange={handlePatientIdChange}
                                                label="User Type"
                                            >
                                                {patient.map((item, index) => {
                                                    return (
                                                        <MenuItem
                                                            value={item.id}
                                                            key={index}
                                                        >
                                                            {item.email}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {question.length > 0 && (
                                        <>
                                            {question.map((data, index) => {
                                                return (
                                                    <>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "chakra" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="Chakra"
                                                                    key={index}
                                                                >
                                                                    Chakra
                                                                </option>
                                                                {chakra.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    item.name
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "major" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="Major"
                                                                    key={index}
                                                                >
                                                                    Major
                                                                </option>
                                                                {major.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    item.name
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "minor" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="Minor"
                                                                    key={index}
                                                                >
                                                                    Minor
                                                                </option>
                                                                {minor.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    item.name
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "organ" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="Organ"
                                                                    key={index}
                                                                >
                                                                    Organ
                                                                </option>
                                                                {organ.map(
                                                                    (
                                                                        item,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                value={
                                                                                    item.name
                                                                                }
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                {" "}
                                                                                {
                                                                                    item.name
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                {...register(
                                                                    "comment" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                label={
                                                                    "Comment"
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    minWidth:
                                                                        "100%",
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "chakrastatus" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="chakrastatus"
                                                                    key={index}
                                                                >
                                                                    Chakra
                                                                    Status
                                                                </option>
                                                                <option
                                                                    value="Depleted"
                                                                    key={index}
                                                                >
                                                                    Depleted
                                                                </option>
                                                                <option
                                                                    value="Normal"
                                                                    key={index}
                                                                >
                                                                    Normal
                                                                </option>
                                                                <option
                                                                    value="Underactive"
                                                                    key={index}
                                                                >
                                                                    Underactive
                                                                </option>
                                                                <option
                                                                    value="Overactive"
                                                                    key={index}
                                                                >
                                                                    Overactive
                                                                </option>
                                                                <option
                                                                    value="Active"
                                                                    key={index}
                                                                >
                                                                    Active
                                                                </option>
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "majorstatus" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="majorstatus"
                                                                    key={index}
                                                                >
                                                                    Major Status
                                                                </option>
                                                                <option
                                                                    value="Depleted"
                                                                    key={index}
                                                                >
                                                                    Depleted
                                                                </option>
                                                                <option
                                                                    value="Normal"
                                                                    key={index}
                                                                >
                                                                    Normal
                                                                </option>
                                                                <option
                                                                    value="Underactive"
                                                                    key={index}
                                                                >
                                                                    Underactive
                                                                </option>
                                                                <option
                                                                    value="Overactive"
                                                                    key={index}
                                                                >
                                                                    Overactive
                                                                </option>
                                                                <option
                                                                    value="Active"
                                                                    key={index}
                                                                >
                                                                    Active
                                                                </option>
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "minorstatus" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="minorstatus"
                                                                    key={index}
                                                                >
                                                                    Minor Status
                                                                </option>
                                                                <option
                                                                    value="Depleted"
                                                                    key={index}
                                                                >
                                                                    Depleted
                                                                </option>
                                                                <option
                                                                    value="Normal"
                                                                    key={index}
                                                                >
                                                                    Normal
                                                                </option>
                                                                <option
                                                                    value="Underactive"
                                                                    key={index}
                                                                >
                                                                    Underactive
                                                                </option>
                                                                <option
                                                                    value="Overactive"
                                                                    key={index}
                                                                >
                                                                    Overactive
                                                                </option>
                                                                <option
                                                                    value="Active"
                                                                    key={index}
                                                                >
                                                                    Active
                                                                </option>
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <select
                                                                {...register(
                                                                    "organstatus" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                style={{
                                                                    minHeight:
                                                                        "40px",
                                                                    minWidth:
                                                                        "100%",
                                                                    padding:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <option
                                                                    value="organstatus"
                                                                    key={index}
                                                                >
                                                                    Organ Status
                                                                </option>
                                                                <option
                                                                    value="Depleted"
                                                                    key={index}
                                                                >
                                                                    Depleted
                                                                </option>
                                                                <option
                                                                    value="Normal"
                                                                    key={index}
                                                                >
                                                                    Normal
                                                                </option>
                                                                <option
                                                                    value="Underactive"
                                                                    key={index}
                                                                >
                                                                    Underactive
                                                                </option>
                                                                <option
                                                                    value="Overactive"
                                                                    key={index}
                                                                >
                                                                    Overactive
                                                                </option>
                                                                <option
                                                                    value="Active"
                                                                    key={index}
                                                                >
                                                                    Active
                                                                </option>
                                                            </select>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <TextField
                                                                {...register(
                                                                    "commentstatus" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                label={
                                                                    "Comment"
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    minWidth:
                                                                        "100%",
                                                                }}
                                                            />
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                        </>
                                    )}
                                    <Grid item xs={6}>
                                        <Stack direction="row" spacing={2}>
                                            <Typography
                                                variant="h5"
                                                align="right"
                                                nowrap={true}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    onClick={addQuestion}
                                                >
                                                    Add
                                                </Button>
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                align="right"
                                                nowrap={true}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    onClick={removeQuestion}
                                                >
                                                    Remove
                                                </Button>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
