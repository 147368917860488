import { useState } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { useForm } from "react-hook-form";

export default function AddQuestion() {
    const { register, handleSubmit } = useForm();
    const [patientid, setPatientid] = useState("");
    const [label, setLabel] = useState("");
    const [type, setType] = useState("");
    const baseURL = process.env.REACT_APP_API_URL;
    const [session, setSession] = useState("");
    const token = useAppStore((state) => state.token);
    const patient = useAppStore((state) => state.patient);
    const useremail = useAppStore((state) => state.useremail);
    const navigate = useNavigate();
    const [question, setQuestion] = useState([
        {
            question1: "",
            answer1: "",
        },
    ]);

    const handlePatientIdChange = (event) => {
        setPatientid(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleSessionChange = (event) => {
        setSession(event.target.value);
    };

    const addQuestion = () => {
        let qarray = {
            question1: "",
            answer1: "",
        };
        setQuestion((question) => [...question, qarray]);
    };

    const removeQuestion = () => {
        setQuestion((question) => question.slice(0, -1));
    };

    const onSubmit = async (data) => {
        const entries = Object.entries(data);
        var que = [];
        var ans = [];
        entries.forEach((value, index) => {
            if (index % 2 == 0) {
                console.log(`${index}: ${value}`);
                que.push(value);
            } else {
                ans.push(value);
            }
        });
        console.log("que: ", que);
        console.log("ans: ", ans);
        createUser(data, que, ans);
    };

    const createUser = async (data, que, ans) => {
        let posturl = baseURL + "question";

        await axios
            .post(
                posturl,
                {
                    patient_id: patientid,
                    type: type,
                    session: session,
                    healer_email: useremail,
                    question: que,
                    answer: data,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                navigate("/question");
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Typography variant="h5" align="center">
                                    Add Question
                                </Typography>
                                <Box height={10} />
                                <IconButton
                                    style={{
                                        position: "absolute",
                                        top: "10",
                                        left: "20",
                                    }}
                                    onClick={() => {
                                        navigate("/question");
                                    }}
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                                <Box height={50} />
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TextField
                                            error={false}
                                            id="session"
                                            name="session"
                                            value={session}
                                            onChange={handleSessionChange}
                                            label="Session"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        >
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Patient
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={patientid}
                                                onBlur={handlePatientIdChange}
                                                onChange={handlePatientIdChange}
                                                label="User Type"
                                            >
                                                {patient.map((item, index) => {
                                                    return (
                                                        <MenuItem
                                                            value={item.id}
                                                            key={index}
                                                        >
                                                            {item.email}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        >
                                            <InputLabel id="label-qualification">
                                                Type
                                            </InputLabel>
                                            <Select
                                                labelId="label-qualification"
                                                value={type}
                                                onChange={handleTypeChange}
                                                onBlur={handleTypeChange}
                                                label="Type"
                                            >
                                                <MenuItem value={"Text"}>
                                                    Text
                                                </MenuItem>
                                                <MenuItem value={"Long Text"}>
                                                    Long Text
                                                </MenuItem>
                                                <MenuItem value={"Drop Down"}>
                                                    Drop Down
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {question.length > 0 && (
                                        <>
                                            {question.map((data, index) => {
                                                return (
                                                    <>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                {...register(
                                                                    "question" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                label={
                                                                    "Question " +
                                                                    (
                                                                        index +
                                                                        1
                                                                    ).toString()
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    minWidth:
                                                                        "100%",
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                {...register(
                                                                    "answer" +
                                                                        (
                                                                            index +
                                                                            1
                                                                        ).toString()
                                                                )}
                                                                label={
                                                                    "Answer " +
                                                                    (
                                                                        index +
                                                                        1
                                                                    ).toString()
                                                                }
                                                                size="small"
                                                                sx={{
                                                                    minWidth:
                                                                        "100%",
                                                                }}
                                                            />
                                                        </Grid>
                                                    </>
                                                );
                                            })}
                                        </>
                                    )}

                                    <Grid item xs={6}>
                                        <Stack direction="row" spacing={2}>
                                            <Typography
                                                variant="h5"
                                                align="right"
                                                nowrap={true}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    onClick={addQuestion}
                                                >
                                                    Add
                                                </Button>
                                            </Typography>
                                            <Typography
                                                variant="h5"
                                                align="right"
                                                nowrap={true}
                                            >
                                                <Button
                                                    variant="outlined"
                                                    onClick={removeQuestion}
                                                >
                                                    Remove
                                                </Button>
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" align="center">
                                            <Button
                                                variant="contained"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
