import { useEffect, useState } from "react";
import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import "../Dashboard.css";
import CountUp from "react-countup";
import GroupIcon from "@mui/icons-material/Group";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";

export default function Home() {
    const baseURL = process.env.REACT_APP_API_URL;
    const updateUsers = useAppStore((state) => state.updateUsers);
    const updateYourHealers = useAppStore((state) => state.updateYourHealers);
    const updateYourPatients = useAppStore((state) => state.updateYourPatients);
    const updatePatient = useAppStore((state) => state.updatePatient);
    const updateCategories = useAppStore((state) => state.updateCategories);
    const [usercount, setUsercount] = useState(0);
    const [healercount, setHealercount] = useState(0);
    const [patientcount, setPatientcount] = useState(0);
    const [hpatient, setHpatient] = useState(0);
    const [hquestion, setHquestion] = useState(0);
    const [pquestion, setPquestion] = useState(0);
    const token = useAppStore((state) => state.token);
    const utype = useAppStore((state) => state.utype);
    const useremail = useAppStore((state) => state.useremail);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        getUsers();
        getHealers();
        getPatients();
        getSymptoms();

        if (utype == "Admin") {
            countUsers();
            getPatient();
        } else if (utype == "Healer") {
            getHealerPatients();
            hcountusers();
        } else if (utype == "Patient") {
            pcountusers();
        }
    }, []);

    const hcountusers = async () => {
        let posturl = baseURL + "hcountusers?que=" + useremail;
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setHpatient(res.data.patient);
                setHquestion(res.data.question);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const pcountusers = async () => {
        let posturl = baseURL + "pcountusers?que=" + useremail;
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                setPquestion(res.data.question);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getPatient = async () => {
        let posturl = baseURL + "patient";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updatePatient(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getHealerPatients = async () => {
        let posturl = baseURL + "healerpatient?que=" + useremail;
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updatePatient(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const countUsers = async () => {
        let posturl = baseURL + "countusers";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                console.log("count: ", res.data.user);
                setUsercount(res.data.user);
                setHealercount(res.data.healer);
                setPatientcount(res.data.patient);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getSymptoms = async () => {
        let posturl = baseURL + "symptom";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateCategories(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getHealers = async () => {
        let posturl = baseURL + "healers";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateYourHealers(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getPatients = async () => {
        let posturl = baseURL + "patient";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateYourPatients(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "users";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateUsers(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    return (
        <>
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    {utype == "Admin" && (
                        <>
                            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Stack direction="row" spacing={2}>
                                            <Card
                                                className="gradientlight"
                                                sx={{
                                                    minWidth: 49 + "%",
                                                    height: 140,
                                                }}
                                            >
                                                <div className="iconstylewhite">
                                                    <AutoFixHighIcon />
                                                </div>
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                        sx={{
                                                            color: "#f0fcfc",
                                                        }}
                                                    >
                                                        <CountUp
                                                            delay={0.2}
                                                            end={healercount}
                                                            duration={0.4}
                                                        />
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            color: "#ccd1d1",
                                                        }}
                                                    >
                                                        Total Healers
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                            <Card
                                                className="gradientlight"
                                                sx={{
                                                    minWidth: 49 + "%",
                                                    height: 140,
                                                }}
                                            >
                                                <div className="iconstylewhite">
                                                    <LocalHotelIcon />
                                                </div>
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                        sx={{
                                                            color: "#f0fcfc",
                                                        }}
                                                    >
                                                        <CountUp
                                                            delay={0.2}
                                                            end={patientcount}
                                                            duration={0.4}
                                                        />
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            color: "#ccd1d1",
                                                        }}
                                                    >
                                                        Total Patients
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {utype == "Healer" && (
                        <>
                            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Stack direction="row" spacing={2}>
                                            <Card
                                                className="gradientlight"
                                                sx={{
                                                    minWidth: 49 + "%",
                                                    height: 140,
                                                }}
                                            >
                                                <div className="iconstylewhite">
                                                    <AutoFixHighIcon />
                                                </div>
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                        sx={{
                                                            color: "#f0fcfc",
                                                        }}
                                                    >
                                                        <CountUp
                                                            delay={0.2}
                                                            end={hpatient}
                                                            duration={0.4}
                                                        />
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            color: "#ccd1d1",
                                                        }}
                                                    >
                                                        Total Patients
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                            <Card
                                                className="gradientlight"
                                                sx={{
                                                    minWidth: 49 + "%",
                                                    height: 140,
                                                }}
                                            >
                                                <div className="iconstylewhite">
                                                    <LiveHelpIcon />
                                                </div>
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                        sx={{
                                                            color: "#f0fcfc",
                                                        }}
                                                    >
                                                        <CountUp
                                                            delay={0.2}
                                                            end={hquestion}
                                                            duration={0.4}
                                                        />
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            color: "#ccd1d1",
                                                        }}
                                                    >
                                                        Total Questions
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {utype == "Patient" && (
                        <>
                            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Stack direction="row" spacing={2}>
                                            <Card
                                                className="gradientlight"
                                                sx={{
                                                    minWidth: 49 + "%",
                                                    height: 140,
                                                }}
                                            >
                                                <div className="iconstylewhite">
                                                    <LiveHelpIcon />
                                                </div>
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div"
                                                        sx={{
                                                            color: "#f0fcfc",
                                                        }}
                                                    >
                                                        <CountUp
                                                            delay={0.2}
                                                            end={pquestion}
                                                            duration={0.4}
                                                        />
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary"
                                                        sx={{
                                                            color: "#ccd1d1",
                                                        }}
                                                    >
                                                        Total Questions
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                </Box>
            </div>
        </>
    );
}
