import React, { useState, useEffect } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppStore } from "../appStore";
import axios from "axios";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function EditHealer() {
    const [userId, setUserId] = useState("");
    const [gender, setGender] = useState("");
    const [level, setLevel] = useState("");
    const [age, setAge] = useState("");
    const [country, setCountry] = useState("");
    const [occupation, setOccupation] = useState("");
    const [address, setAddress] = useState("");
    const [mobile, setMobile] = useState("");
    const [type, setType] = useState("Active");
    const [status, setStatus] = useState("Published");
    const [description, setDescription] = useState("");
    const baseURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const token = useAppStore((state) => state.token);
    const location = useLocation();
    const yourhealers = useAppStore((state) => state.yourhealers);

    useEffect(() => {
        console.log("FID: ", location.state);
        setUserId(location.state.user_id);
        setMobile(location.state.mobile);
        setGender(location.state.gender);
        setLevel(location.state.level);
        setAge(location.state.age);
        setCountry(location.state.country);
        setOccupation(location.state.occupation);
        setAddress(location.state.address);
        setType(location.state.type);
        setStatus(location.state.status);
        setDescription(location.state.description);
    }, []);

    const createUser = async (url) => {
        let posturl = baseURL + "healer/" + location.state.id;
        const tid = toast.loading("Please wait...");

        await axios
            .put(
                posturl,
                {
                    user_id: userId,
                    gender: gender,
                    level: level,
                    age: age,
                    country: country,
                    occupation: occupation,
                    address: address,
                    mobile: mobile,
                    type: type,
                    status: status,
                    description: description,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                toast.update(tid, {
                    render: "Created!",
                    type: "success",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                });
                navigate("/healer");
            })
            .catch(function (error) {
                toast.update(tid, {
                    render: error,
                    type: "error",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                    theme: "light",
                });
                console.log("kcheckpost" + error); //return 429
            });
    };

    const handleNameChange = (event) => {
        setUserId(event.target.value);
    };

    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };

    const handleLevelChange = (event) => {
        setLevel(event.target.value);
    };

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    };

    const handleOccupationChange = (event) => {
        setOccupation(event.target.value);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handleMobileChange = (event) => {
        setMobile(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Typography variant="h5" align="center">
                                Edit Healer
                            </Typography>
                            <Box height={10} />
                            <IconButton
                                style={{
                                    position: "absolute",
                                    top: "-10",
                                    left: "20",
                                }}
                                onClick={() => {
                                    navigate("/healer");
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box height={20} />
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                            marginTop: "30px",
                                        }}
                                    >
                                        <InputLabel id="demo-simple-select-standard-label">
                                            Healer
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={userId}
                                            onBlur={handleNameChange}
                                            onChange={handleNameChange}
                                            label="Name"
                                        >
                                            {yourhealers.map((item, index) => {
                                                return (
                                                    <MenuItem
                                                        value={item.id}
                                                        key={index}
                                                    >
                                                        {item.email}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            marginTop: "30px",
                                            minWidth: "100%",
                                        }}
                                    >
                                        <InputLabel id="label-qualification">
                                            Gender
                                        </InputLabel>
                                        <Select
                                            labelId="label-qualification"
                                            value={gender}
                                            onChange={handleGenderChange}
                                            onBlur={handleGenderChange}
                                            label="gender"
                                        >
                                            <MenuItem value={"Female"}>
                                                Female
                                            </MenuItem>
                                            <MenuItem value={"Male"}>
                                                Male
                                            </MenuItem>
                                            <MenuItem value={"Other"}>
                                                Other
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={age}
                                        onChange={handleAgeChange}
                                        label="Age"
                                        type="number"
                                        size="small"
                                        sx={{
                                            marginTop: "30px",
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={mobile}
                                        onChange={handleMobileChange}
                                        label="Mobile"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={occupation}
                                        onChange={handleOccupationChange}
                                        label="Occupation"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={type}
                                        onChange={handleTypeChange}
                                        label="Type"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={status}
                                        onChange={handleStatusChange}
                                        label="Status"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={country}
                                        onChange={handleCountryChange}
                                        label="Country"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={address}
                                        onChange={handleAddressChange}
                                        label="Address"
                                        type="text"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            minWidth: "100%",
                                        }}
                                    >
                                        <InputLabel id="label-qualification">
                                            Level
                                        </InputLabel>
                                        <Select
                                            labelId="label-qualification"
                                            value={level}
                                            onChange={handleLevelChange}
                                            onBlur={handleLevelChange}
                                            label="level"
                                        >
                                            <MenuItem value={"Fresher"}>
                                                Fresher
                                            </MenuItem>
                                            <MenuItem value={"ACPH"}>
                                                ACPH
                                            </MenuItem>
                                            <MenuItem value={"Acph + arhatic"}>
                                                Acph + arhatic
                                            </MenuItem>
                                            <MenuItem value={"CPH + level 1"}>
                                                CPH + level 1
                                            </MenuItem>
                                            <MenuItem value={"CPP+ level 2"}>
                                                CPP+ level 2
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        error={false}
                                        id="description"
                                        name="description"
                                        value={description}
                                        onChange={handleDescriptionChange}
                                        label="Description"
                                        size="small"
                                        sx={{
                                            marginTop: "0px",
                                            minWidth: "100%",
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h5" align="center">
                                        <Button
                                            variant="contained"
                                            onClick={createUser}
                                        >
                                            Submit
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
