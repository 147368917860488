import { Routes, Route, HashRouter } from "react-router-dom";
import Healer from "./pages/Healer";
import AddHealer from "./healer/AddHealer";
import EditHealer from "./healer/EditHealer";
import Patient from "./pages/Patient";
import AddPatient from "./patient/AddPatient";
import EditPatient from "./patient/EditPatient";
import Symptom from "./pages/Symptom";
import Chakra from "./pages/Chakra";
import Message from "./pages/Message";
import Question from "./pages/Question";
import AddQuestion from "./question/AddQuestion";
import EditQuestion from "./question/EditQuestion";
import Scanning from "./pages/Scanning";
import AddScanning from "./scanning/AddScanning";
import EditScanning from "./scanning/EditScanning";
import User from "./pages/User";
import Login from "./Login";
import Home from "./pages/Home";
import { useAppStore } from "./appStore";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";

export default function App() {
    const utype = useAppStore((state) => state.utype);

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path="/" exact element={<Login />}></Route>
                    <Route
                        path="/register"
                        exact
                        element={<Register />}
                    ></Route>
                    <Route
                        path="/reset-password"
                        exact
                        element={<ForgotPassword />}
                    ></Route>
                    {(utype === "Admin" ||
                        utype === "Healer" ||
                        utype === "Patient") && (
                        <>
                            <Route
                                path="/home"
                                exact
                                element={<Home />}
                            ></Route>
                            <Route
                                path="/question"
                                exact
                                element={<Question />}
                            ></Route>
                            <Route
                                path="/edit-question"
                                exact
                                element={<EditQuestion />}
                            ></Route>
                            <Route
                                path="/scanning"
                                exact
                                element={<Scanning />}
                            ></Route>
                            <Route
                                path="/edit-scanning"
                                exact
                                element={<EditScanning />}
                            ></Route>
                            <Route
                                path="/message"
                                exact
                                element={<Message />}
                            ></Route>
                        </>
                    )}
                    {(utype === "Admin" || utype === "Healer") && (
                        <>
                            <Route
                                path="/home"
                                exact
                                element={<Home />}
                            ></Route>
                            <Route
                                path="/patient"
                                exact
                                element={<Patient />}
                            ></Route>
                            <Route
                                path="/add-patient"
                                exact
                                element={<AddPatient />}
                            ></Route>
                            <Route
                                path="/edit-patient"
                                exact
                                element={<EditPatient />}
                            ></Route>
                            <Route
                                path="/question"
                                exact
                                element={<Question />}
                            ></Route>
                            <Route
                                path="/add-question"
                                exact
                                element={<AddQuestion />}
                            ></Route>
                            <Route
                                path="/edit-question"
                                exact
                                element={<EditQuestion />}
                            ></Route>
                            <Route
                                path="/scanning"
                                exact
                                element={<Scanning />}
                            ></Route>
                            <Route
                                path="/add-scanning"
                                exact
                                element={<AddScanning />}
                            ></Route>
                            <Route
                                path="/edit-scanning"
                                exact
                                element={<EditScanning />}
                            ></Route>
                        </>
                    )}
                    {utype === "Admin" && (
                        <>
                            <Route
                                path="/healer"
                                exact
                                element={<Healer />}
                            ></Route>
                            <Route
                                path="/add-healer"
                                exact
                                element={<AddHealer />}
                            ></Route>
                            <Route
                                path="/edit-healer"
                                exact
                                element={<EditHealer />}
                            ></Route>
                            <Route
                                path="/symptom"
                                exact
                                element={<Symptom />}
                            ></Route>
                            <Route
                                path="/chakra"
                                exact
                                element={<Chakra />}
                            ></Route>
                            <Route
                                path="/user"
                                exact
                                element={<User />}
                            ></Route>
                        </>
                    )}
                </Routes>
            </HashRouter>
        </>
    );
}
