import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function HealerList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const updateHealers = useAppStore((state) => state.updateHealers);
    const token = useAppStore((state) => state.token);
    const navigate = useNavigate();
    const healers = useAppStore((state) => state.healers);

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        getUsers();
    }, []);

    const getUsers = async () => {
        let posturl = baseURL + "healer";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateHealers(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "healer/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                console.log(res.data);
                getUsers();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            updateHealers([v]);
        } else {
            getUsers();
        }
    };

    const editData = (
        id,
        name,
        user_id,
        gender,
        level,
        age,
        country,
        occupation,
        address,
        mobile,
        type,
        status,
        description
    ) => {
        const data = {
            id: id,
            name: name,
            user_id: user_id,
            gender: gender,
            level: level,
            age: age,
            country: country,
            occupation: occupation,
            address: address,
            mobile: mobile,
            type: type,
            status: status,
            description: description,
        };
        navigate("/edit-healer", {
            state: {
                id: id,
                name: name,
                user_id: user_id,
                gender: gender,
                level: level,
                age: age,
                country: country,
                occupation: occupation,
                address: address,
                mobile: mobile,
                type: type,
                status: status,
                description: description,
            },
        });
    };

    return (
        <>
            <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ padding: "20px" }}
                >
                    Healers
                </Typography>
                <Divider />
                <Box height={10} />
                <Stack direction="row" spacing={2} className="my-2 mb-2">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={healers}
                        sx={{ width: 300 }}
                        onChange={(e, v) => filterData(v)}
                        getOptionLabel={(healers) => healers.email || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Search Healer"
                            />
                        )}
                    />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Button
                        variant="contained"
                        endIcon={<AddCircleIcon />}
                        onClick={() => {
                            navigate("/add-healer");
                        }}
                    >
                        Add
                    </Button>
                </Stack>
                <Box height={10} />
                {healers.length > 0 && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Healer
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Mobile
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Type
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Level
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Created At
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px" }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {healers
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={index}
                                            >
                                                <TableCell align="left">
                                                    {row.email}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.mobile}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.type}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {String(row.level)}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(
                                                        row.created_at
                                                    ).format("MMMM Do YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                    >
                                                        <EditIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                editData(
                                                                    row.id,
                                                                    row.name,
                                                                    row.user_id,
                                                                    row.gender,
                                                                    row.level,
                                                                    row.age,
                                                                    row.country,
                                                                    row.occupation,
                                                                    row.address,
                                                                    row.mobile,
                                                                    row.type,
                                                                    row.status,
                                                                    row.description
                                                                );
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "darkred",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                deleteUser(
                                                                    row.id
                                                                );
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={healers.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {healers.length == 0 && (
                <>
                    <Paper
                        sx={{
                            width: "98%",
                            overflow: "hidden",
                            padding: "12px",
                        }}
                    >
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={30}
                        />
                        <Box height={40} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                    </Paper>
                </>
            )}
        </>
    );
}
