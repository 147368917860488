import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function EditForm({ fid, closeEvent }) {
    const [name, setName] = useState("");
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const baseURL = process.env.REACT_APP_API_URL;
    const updateMessages = useAppStore((state) => state.updateMessages);
    const token = useAppStore((state) => state.token);
    const useremail = useAppStore((state) => state.useremail);
    const users = useAppStore((state) => state.users);

    useEffect(() => {
        console.log("FID: " + fid.id);
        setName(fid.name);
        setEmail(fid.email);
        setTo(fid.to);
        setFrom(fid.from);
        setMessage(fid.message);
    }, []);

    const createUser = async (url) => {
        let posturl = baseURL + "message/" + fid.id;

        await axios
            .put(
                posturl,
                {
                    name: name,
                    email: useremail,
                    from: useremail,
                    to: to,
                    message: message,
                },
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                console.log(res.data);
                getUsers();
                closeEvent();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const getUsers = async () => {
        let posturl = baseURL + "message";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateMessages(res.data);
                console.log(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleToChange = (event) => {
        setTo(event.target.value);
    };

    return (
        <>
            <Box sx={{ m: 2 }} />
            <Typography variant="h5" align="center">
                Edit Message
            </Typography>
            <IconButton
                style={{ position: "absolute", top: "0", right: "0" }}
                onClick={closeEvent}
            >
                <CloseIcon />
            </IconButton>
            <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                    <FormControl
                        variant="outlined"
                        size="small"
                        sx={{
                            minWidth: "100%",
                            marginTop: "30px",
                        }}
                    >
                        <InputLabel id="demo-simple-select-standard-label">
                            User
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={to}
                            onBlur={handleToChange}
                            onChange={handleToChange}
                            label="Name"
                        >
                            {users.map((item, index) => {
                                return (
                                    <MenuItem value={item.email} key={index}>
                                        {item.email}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid> */}
                <Grid item xs={12}>
                    <TextField
                        value={to}
                        onChange={handleToChange}
                        label="Enter Email of the Person"
                        size="small"
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={message}
                        onChange={handleMessageChange}
                        label="Message"
                        size="small"
                        sx={{ minWidth: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        <Button variant="contained" onClick={createUser}>
                            Submit
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
